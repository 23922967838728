import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import { BlogLayout } from "../../components/BlogLayout"

class PrintStatsProject extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Print Stats" />
        <BlogLayout>
          <div className="blog-title">Print Stats</div>

          <div className="tag-container">
            <a className="live">⚙️ src (soon)</a>
          </div>
          <p className="subtitle">
            Used by the Stony Brook Division of Information and Technology
            (DoIT) to monitor the health of 100+ printers across the Stony Brook
            Main and Southampton campus. Technicians rely on the Print Stats
            system to receive real-time notifications and generate reports about
            monthly usage to optimize supply delivery routes.
          </p>
          <div className="tech-stack">
            {technicalStack.map(t => (
              <span className="item">{t}</span>
            ))}
          </div>
          <hr />

          {/* <h3>Screenshots</h3>
          <hr /> */}
        </BlogLayout>
      </Layout>
    )
  }
}

export default PrintStatsProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const technicalStack = ["C#", "EF Core", "PostgreSQL", "Vue.js", "RabbitMQ"]
